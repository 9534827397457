import React from 'react'

const Signup = () => {
  return (
    <>
      hello
    </>
  )
}

export default Signup
